import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Login } from './pages/Login';
import { NotFound } from './pages/NotFound';
import { SearchContact } from './pages/SearchContact';
import { Account } from './pages/Account';
import { PrivateRoute } from './components/auth/PrivateRoute';
import { ContactResults } from './pages/ContactResults';
import { UpdateContact } from './pages/UpdateContact';
import { CreateContact } from './pages/CreateContact';
import { Donazioni } from './pages/Donazioni';
import { CreateDonazione } from './pages/CreateDonazione';
import { Template } from './components/commons/Template';
import { Summary } from './pages/Summary';
import { Ocr } from './pages/Ocr';

function App() {
  // Extracts pathname property(key) from an object
  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/*" element={<NotFound />} />

      <Route element={<PrivateRoute />}>
        <Route path="/account" element={<Account />} />
        <Route index element={<SearchContact />} />
        <Route path="/ocr" element={<Ocr />} />
        <Route element={<Template />}>
          <Route path="/results-people" element={<ContactResults />} />
          <Route path="/update-contact" element={<UpdateContact />} />
          <Route path="/create-contact" element={<CreateContact />} />
          <Route path="/donazioni" element={<Donazioni />} />
          <Route path="/create-donazione" element={<CreateDonazione />} />
          <Route path="/rendicontazione" element={<Summary />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
