/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { Contact } from '../../interface/contact';
import { NuovaDonazione } from '../../interface/donazione';
import { useProducts } from '../products/ProductProvider';
import ConfirmModal from '../commons/ConfirmModal';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { CodeChip } from '../commons/CodeChip';
import { SkeletonForm } from '../commons/Skeleton';
import { formatPrice } from '../../utils/formatPrice';
import { useLocation, useNavigate } from 'react-router-dom';
import useDonazione from '../../hooks/useDonazione';

interface Props {
  contact: Contact | null;
  onSubmit: (value: NuovaDonazione) => void;
  onCancel: () => void;
  isLoading?: boolean;
}

export const DonazioneForm = ({ contact, onSubmit, onCancel, isLoading: createLoading }: Props) => {
  const { products, isLoading: productsIsLoading } = useProducts();
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const { control, handleSubmit, setValue } = useFormContext();
  const navigate = useNavigate();
  const location = useLocation();

  const getScannedQuantity = () => {
    const scannedQuantity = location.state?.receipt?.qty;
    return scannedQuantity ? Number(scannedQuantity) : 0;
  };

  const { productsToBuy, freeOffer, isAmountLess, totalToPay, setProducts, incrementQuantity, decrementQuantity } =
    useDonazione({
      initialImporto: getScannedQuantity(),
      initialProducts: products,
      onChangeImporto: (value) => setValue('importo', value),
      onChangeProducts: (values) => setValue('prodotti', values),
    });

  useEffect(() => {
    setProducts(products, getScannedQuantity());
  }, [products]);

  const conditionalSubmit = (data: any) => {
    onSubmit(data);
  };

  const handleCancel = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setConfirmOpen(true);
  };

  const handleConfirmCancel = () => {
    setConfirmOpen(false);
    onCancel();
  };

  sessionStorage.getItem('hasPos') === 'true' && navigate('/');

  if (!contact) {
    return (
      <div className="container-main">
        <Alert severity="warning"> Nessun contatto selezionato</Alert>
      </div>
    );
  }

  if (productsIsLoading) {
    return <SkeletonForm />;
  }

  if (!products || products?.length === 0) {
    return <Alert severity="error">Nessun prodotto trovato</Alert>;
  }

  setValue('id_contatto', contact.id);

  return (
    <div className="container-main">
      <h1 style={{ marginBottom: 30 }}>Nuova donazione</h1>

      <CodeChip contactId={contact.id} contactName={`${contact.nome} ${contact.cognome}`} />

      <form onSubmit={handleSubmit(conditionalSubmit)}>
        <div className="products-list">
          {productsToBuy.map((prod, index) => {
            return (
              <div className="product-container" key={index}>
                <div className="product-info">
                  <span>{prod.nome}</span>
                  <span>
                    {formatPrice(prod.prezzo)}{' '}
                    {prod.quantita > 1 && `x ${prod.quantita} = ${formatPrice(prod.prezzo * prod.quantita)}`}
                  </span>
                </div>
                <div className="product-quantity">
                  <IconButton
                    aria-label="remove"
                    color="primary"
                    disabled={prod.quantita === 0}
                    onClick={() => decrementQuantity(prod.id)}
                  >
                    <RemoveCircleIcon />
                  </IconButton>
                  <span style={{ width: '20px', textAlign: 'center' }}>{prod.quantita}</span>

                  <IconButton aria-label="add" color="primary" onClick={() => incrementQuantity(prod.id)}>
                    <AddCircleIcon />
                  </IconButton>
                </div>
              </div>
            );
          })}
        </div>

        <Controller
          name="metodo_pagamento"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <FormControl size="small">
              <InputLabel>Metodo di pagamento</InputLabel>
              <Select label="Metodo di pagamento" {...field} value={field.value}>
                <MenuItem value="contanti">Contanti</MenuItem>
                <MenuItem value="pos">Pos</MenuItem>
              </Select>
            </FormControl>
          )}
        />

        <p style={{ fontSize: '1.3rem' }}>
          Totale donazione: <strong> {formatPrice(totalToPay + freeOffer)}</strong>
        </p>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Button
            variant="contained"
            type="submit"
            disabled={isAmountLess || createLoading}
            startIcon={createLoading && <CircularProgress size="1rem" color="inherit" />}
          >
            Salva donazione
          </Button>

          <Button variant="outlined" type="submit" onClick={handleCancel} disabled={createLoading}>
            Annulla
          </Button>
        </Box>
      </form>

      <ConfirmModal
        open={confirmOpen}
        text="Vuoi annullare l'inserimento?"
        confirmButtonText="Annulla inserimento"
        onCancel={() => setConfirmOpen(false)}
        onConfirm={() => handleConfirmCancel()}
        backButtonText="Torna alla donazione"
      />
    </div>
  );
};
