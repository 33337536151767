import { useLocation, useNavigate } from 'react-router-dom';
import OcrPresentational from '../components/ocr/OcrPresentational';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import PopoverButton from '../components/ocr/PopoverButton';
import { Box } from '@mui/material';

export const Ocr = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);
  const scanType = searchParams.get('type');
  const scanName = searchParams.get('name');

  return (
    <div className="container-main">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Box
          className="back-button cursor-pointer"
          sx={{
            flex: 1,
            marginBottom: 1,
          }}
          onClick={() => navigate(-1)}
        >
          <ChevronLeftIcon /> Indietro
        </Box>
        <PopoverButton searchParams={{ type: scanType, name: scanName }} />
      </Box>
      <OcrPresentational searchParams={{ type: scanType, name: scanName }} />
    </div>
  );
};
