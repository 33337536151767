import * as React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { Person, Search, CardGiftcard, SummarizeRounded } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';

export default function NavBar() {
  const navigate = useNavigate();
  const location = useLocation();

  const hasPos = sessionStorage.getItem('hasPos') === 'true';

  return (
    <div className="nav-bar">
      <BottomNavigation
        showLabels
        value={location.pathname}
        onChange={(event, newValue) => {
          navigate(newValue);
        }}
      >
        <BottomNavigationAction value="/" icon={<Search />} />
        {!hasPos && <BottomNavigationAction value="/donazioni" icon={<CardGiftcard />} />}
        <BottomNavigationAction value="/account" icon={<Person />} />
        <BottomNavigationAction value="/rendicontazione" icon={<SummarizeRounded />} />
      </BottomNavigation>
    </div>
  );
}
