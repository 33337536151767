import * as yup from 'yup';

export const schemaSummary = yup.object().shape({
  qt_ricevuti: yup
    .string()
    .matches(/^[0-9]*$/, 'Questo campo deve contenere solo numeri')
    .required('Campo obbligatorio'),

  qt_distribuiti: yup
    .string()
    .matches(/^[0-9]*$/, 'Questo campo deve contenere solo numeri')
    .required('Campo obbligatorio'),

  qt_non_utilizzabili: yup
    .string()
    .matches(/^[0-9]*$/, 'Questo campo deve contenere solo numeri')
    .required('Campo obbligatorio'),

  qt_rimanenze: yup
    .string()
    .matches(/^[0-9]*$/, 'Questo campo deve contenere solo numeri')
    .required('Campo obbligatorio'),

  tot_contributi: yup
    .string()
    .matches(/^[0-9]*$/, 'Questo campo deve contenere solo numeri')
    .required('Campo obbligatorio'),

  qt_ricevute_cartacee: yup
    .string()
    .matches(/^[0-9]*$/, 'Questo campo deve contenere solo numeri')
    .required('Campo obbligatorio'),

  qt_ricevute_digitali: yup
    .string()
    .matches(/^[0-9]*$/, 'Questo campo deve contenere solo numeri')
    .required('Campo obbligatorio'),

  qt_richiesta_anno_successivo: yup
    .string()
    .matches(/^[0-9]*$/, 'Questo campo deve contenere solo numeri')
    .required('Campo obbligatorio'),

  qt_piazze_esterne: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          nome: yup.string().nullable(),
          quantità: yup
            .string()

            .nullable()
            .when('nome', {
              is: (nome: string) => nome !== '',
              then: () =>
                yup
                  .string()
                  .matches(/^[0-9]*$/, 'Questo campo deve contenere solo numeri')
                  .required('Quantità del piazza esterna è obbligatoria'),
            }),
        })
        .required('Piazze esterne devono contenere almeno un elemento'),
    )
    .required('Piazze esterne è obbligatorio'),

  qt_date_piazze_esterne: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          nome: yup.string().nullable(),
          quantità: yup
            .string()

            .nullable()
            .when('nome', {
              is: (nome: string) => nome !== '',
              then: () =>
                yup
                  .string()
                  .matches(/^[0-9]*$/, 'Questo campo deve contenere solo numeri')
                  .required('Quantità del piazza esterna è obbligatoria'),
            }),
        })
        .required('Piazze esterne devono contenere almeno un elemento'),
    )
    .required('Piazze esterne è obbligatorio'),

  commenti: yup.string().trim(),
});
