/* eslint-disable no-console */
import { Alert, Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { searchContacts } from '../api/searchContacts';
import { SearchCriteria, Contact } from '../interface/contact';
import { useAuth } from '../components/auth/AuthProvider';
import moment from 'moment';
import { SkeletonContactList } from '../components/commons/Skeleton';

export const ContactResults = () => {
  const navigate = useNavigate();
  const { token } = useAuth();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  // const [{ token }] = useAuth();

  const searchCriteria: SearchCriteria = {
    nome: searchParams.get('nome') || '',
    cognome: searchParams.get('cognome') || '',
    citta: searchParams.get('citta') || '',
  };

  const { data, isLoading, error } = useQuery(
    ['contacts', searchCriteria],
    () => searchContacts(searchCriteria, token),
    {
      staleTime: 60 * 1000, // 1 min
      onError: (error: Error) => {
        toast.error(error.message);
      },
    },
  );

  const contactCard = (contacts?: Contact[]) =>
    contacts?.length ? (
      <div className="person-card-box ">
        {contacts.map((contact, i) => (
          <div
            key={i}
            className="person-card cursor-pointer"
            onClick={() =>
              navigate(`/update-contact?id=${contact.id}`, {
                state: location.state?.receipt && { receipt: location.state?.receipt },
              })
            }
          >
            <div>
              <h3>
                {contact.nome} {contact.cognome}
              </h3>
              <p>
                CF: <strong>{contact.codice_fiscale} </strong>
              </p>
              <p>
                Email: <strong>{contact.indirizzo_email}</strong>
              </p>
              <p>
                Data di nascita:{' '}
                <strong>{contact.data_nascita && moment(contact.data_nascita).format('DD/MM/YYYY')}</strong>
              </p>
              <p>
                Residenza:
                <strong>
                  {' '}
                  {contact.citta} {contact.provincia}
                </strong>
              </p>
              <p>
                Indirizzo:
                <strong> {`${contact.indirizzo} ${contact.indirizzo_civico}`.trim()}</strong>
              </p>
              <p>
                Codice contatto: <strong>{contact.codice_contatto || ''}</strong>
              </p>
            </div>
            <ChevronRightIcon color="primary" />
          </div>
        ))}
      </div>
    ) : error ? (
      <Alert severity="error"> Si è verificato un errore durante la ricerca. </Alert>
    ) : (
      <Alert severity="warning"> Non ci sono anagrafiche inserite in AIRC corrispondenti alla tua ricerca</Alert>
    );

  return (
    <div className="container-main gap">
      <div>
        <h1>Risultati di ricerca</h1>
        <p className="subtitle">Compatibili con Nome, Cognome e Località</p>
      </div>

      {isLoading ? <SkeletonContactList /> : contactCard(data?.conCittaTarget)}

      <div>
        <h1>Altri risultati</h1>
        <p className="subtitle">Compatibili con Nome e Cognome</p>
      </div>

      {isLoading ? <SkeletonContactList /> : contactCard(data?.senzaCitta.concat(data?.altreCitta))}

      <Button
        variant="contained"
        disabled={isLoading}
        fullWidth
        startIcon={<AddIcon />}
        onClick={() =>
          navigate(`/create-contact?nome=${searchCriteria.nome}&cognome=${searchCriteria.cognome}`, {
            state: location.state?.receipt && { receipt: location.state?.receipt },
          })
        }
      >
        Nuova anagrafica
      </Button>
    </div>
  );
};
