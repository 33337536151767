/* eslint-disable max-lines */
import { useMemo, useState } from 'react';
import { Button, CircularProgress, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import NavBar from '../components/commons/NavBar';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAuth } from '../components/auth/AuthProvider';
import { schemaSummary } from '../components/validation/schemaSummary';
import SummaryTextFieldAccordion from '../components/summary/SummaryTextFieldAccordion';
import SummaryTextFieldAccordionTo from '../components/summary/SummaryTextFieldAccordionTo';
import { parseExternalEvents } from '../components/summary/utils/parseExternalEvents';
import { parseExternalEventsFromServer } from '../components/summary/utils/parseExternalEventsFromServer';
import { fetchSummary } from '../api/fetchSummary';
import { saveSummary } from '../api/saveSummary';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { ISummaryData, ISummaryFormData } from '../interface/summary';
import { getMainProduct } from '../api/getMainProduct';
import { SkeletonForm } from '../components/commons/Skeleton';
import { sendSummary } from '../api/sendSummary';
import ConfirmModal from '../components/commons/ConfirmModal';

const summaryDefaultValues = {
  qt_ricevuti: '',
  qt_distribuiti: '',
  qt_non_utilizzabili: '',
  qt_rimanenze: '',
  tot_contributi: '',
  qt_ricevute_cartacee: '',
  qt_ricevute_digitali: '',
  qt_richiesta_anno_successivo: '',
  qt_piazze_esterne: [{ nome: '', quantità: '' }],
  qt_date_piazze_esterne: [{ nome: '', quantità: '' }],
  commenti: '',
};

export const Summary = () => {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const { handleSubmit, control, reset } = useForm<ISummaryFormData>({
    resolver: yupResolver(schemaSummary),
    defaultValues: summaryDefaultValues,
  });
  const { token } = useAuth();
  const queryClient = useQueryClient();

  const { data: product, isLoading: isLoadingProduct } = useQuery(['main-product'], () => getMainProduct(token), {
    refetchOnWindowFocus: false,
    staleTime: 30000,
  });

  const { data: rendicontazione, isFetching: isLoadingSummary } = useQuery(
    ['summary-data'],
    () => fetchSummary(token),
    {
      refetchOnWindowFocus: false,
      staleTime: 1000,
      retry: 0,
      onSuccess: (data) => {
        const formData = {
          ...data,
          qt_piazze_esterne: parseExternalEventsFromServer(data.qt_piazze_esterne),
          qt_date_piazze_esterne: parseExternalEventsFromServer(data.qt_date_piazze_esterne),
        };
        reset(formData as any);
      },
    },
  );

  const { mutate: mutateCreate, isLoading } = useMutation<ISummaryData, Error, ISummaryData>((data: ISummaryData) => {
    return saveSummary(data, token);
  });

  const mainProduct = useMemo(() => {
    if (product) {
      return product.nome.split(' ')[0];
    } else {
      return 'Prodotti';
    }
  }, [product]);

  const setWordGender = (word: string) => {
    if (mainProduct?.endsWith('e')) {
      return word.substring(0, word.length - 1) + 'e';
    } else {
      return word;
    }
  };

  const closedEvent = !!rendicontazione?.sent;

  const onSubmit = (summaryFormdata: ISummaryFormData) => {
    const { qt_piazze_esterne, qt_date_piazze_esterne } = summaryFormdata;
    const parsedExternalEvents = parseExternalEvents(qt_piazze_esterne);
    const parsedExternalEventsTo = parseExternalEvents(qt_date_piazze_esterne);

    const data = {
      ...summaryFormdata,
      qt_piazze_esterne: parsedExternalEvents,
      qt_date_piazze_esterne: parsedExternalEventsTo,
    };

    mutateCreate(data, {
      onSuccess: () => {
        toast.success('La rendicontazione è salvata in bozza! Puoi modificarla quando vuoi.');
      },
      onError: (error: Error) => {
        toast.error(error.message);
      },
    });
  };

  const onCloseEvent = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setConfirmOpen(true);
  };

  const handleCloseEvent = async () => {
    try {
      await sendSummary(token);
      toast.success("Hai chiuso l'evento! La rendicontazione sarà inviata agli uffici territoriali.");
      queryClient.invalidateQueries({ queryKey: ['summary-data'] });
      setConfirmOpen(false);
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  if (isLoadingProduct || isLoadingSummary) {
    return <SkeletonForm />;
  }

  return (
    <div className="container-main">
      <h1>Rendicontazione Allegato B</h1>

      {closedEvent && (
        <center>
          <i>
            <small>Evento chiuso non modificabile</small>
          </i>
        </center>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="qt_ricevuti"
          control={control}
          disabled={closedEvent}
          render={({ field, fieldState: { error } }) => (
            <TextField
              size="small"
              label={`${mainProduct} ${setWordGender('Ricevuti')} *`}
              {...field}
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
        <Controller
          name="qt_distribuiti"
          control={control}
          disabled={closedEvent}
          render={({ field, fieldState: { error } }) => (
            <TextField
              size="small"
              label={`${mainProduct} ${setWordGender('Distribuiti')} *`}
              {...field}
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
        <Controller
          name="qt_non_utilizzabili"
          control={control}
          disabled={closedEvent}
          render={({ field, fieldState: { error } }) => (
            <TextField
              size="small"
              label={`${mainProduct} Non Utilizzabili *`}
              {...field}
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
        <Controller
          name="qt_rimanenze"
          control={control}
          disabled={closedEvent}
          render={({ field, fieldState: { error } }) => (
            <TextField size="small" label="Rimanenze *" {...field} error={!!error} helperText={error?.message} />
          )}
        />

        <Controller
          name="tot_contributi"
          control={control}
          disabled={closedEvent}
          render={({ field, fieldState: { error } }) => (
            <TextField
              size="small"
              label="Contributi raccolti *"
              {...field}
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
        <Controller
          name="qt_ricevute_cartacee"
          control={control}
          disabled={closedEvent}
          render={({ field, fieldState: { error } }) => (
            <TextField
              size="small"
              label="Ricevute Cartacee Utilizzate *"
              {...field}
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
        <Controller
          name="qt_ricevute_digitali"
          control={control}
          disabled={closedEvent}
          render={({ field, fieldState: { error } }) => (
            <TextField
              size="small"
              label="Ricevute Digitali Utilizzate *"
              {...field}
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
        <Controller
          name="qt_richiesta_anno_successivo"
          control={control}
          disabled={closedEvent}
          render={({ field, fieldState: { error } }) => (
            <TextField
              size="small"
              label={`${mainProduct} ${setWordGender('Richiesti')} per Il ${new Date().getFullYear() + 1} *`}
              {...field}
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
        <SummaryTextFieldAccordion
          accordionSummaryTitle={`${mainProduct} ${setWordGender('Presi')} da altre piazze`}
          control={control}
          disabled={closedEvent}
        />
        <SummaryTextFieldAccordionTo
          accordionSummaryTitle={`${mainProduct} ${setWordGender('Dati')} ad altre piazze`}
          control={control}
          disabled={closedEvent}
        />
        <Controller
          name="commenti"
          control={control}
          disabled={closedEvent}
          render={({ field, fieldState: { error } }) => (
            <TextField
              multiline
              minRows={2}
              size="small"
              label="Commenti..."
              {...field}
              error={!!error}
              helperText={error?.message}
            />
          )}
        />

        {closedEvent && (
          <center>
            <i>
              <small>Evento chiuso non modificabile</small>
            </i>
          </center>
        )}

        <Button
          variant="contained"
          type="submit"
          disabled={isLoading || closedEvent}
          startIcon={isLoading && <CircularProgress size={16} color={'inherit'} />}
        >
          Salva
        </Button>
        <Button
          variant="outlined"
          type="button"
          disabled={isLoading || closedEvent || !rendicontazione}
          style={{ marginBottom: 20 }}
          startIcon={isLoading && <CircularProgress size={16} color={'inherit'} />}
          onClick={onCloseEvent}
        >
          Chiudi evento
        </Button>
      </form>

      <ConfirmModal
        open={confirmOpen}
        title="Vuoi chiudere l'evento?"
        text="Chiudendo l'evento la rendicontazione sarà inviata agli uffici territoriali. Se chiudi l'evento non potrai riaprirlo."
        confirmButtonText="Chiudi evento"
        backButtonText="Annulla"
        onCancel={() => setConfirmOpen(false)}
        onConfirm={() => handleCloseEvent()}
      />

      <NavBar />
    </div>
  );
};
