/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-lines */
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@mui/material';
import { DateField } from '@mui/x-date-pickers';
import moment from 'moment-timezone';
import { useEffect, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import codiceFiscaleReverse from '../../utils/codiceFiscaleReverse';
import toTitleCase from '../../utils/toTitleCase';
import { CodeChip } from '../commons/CodeChip';
import { Consensi } from './Consensi';
import SearchAddress from '../commons/SearchAddress';
import { FormattedAddress } from '../../api/searchGoogleAddress';

export type SubmitOptions = { next?: 'createDonazione'; isDirty: boolean } | undefined;

interface Props {
  onSubmit: (data: any, options?: SubmitOptions) => void;
  isLoading: boolean;
}

export const UpsertForm: React.FC<Props> = ({ onSubmit, isLoading }) => {
  const location = useLocation();

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { isValid, isSubmitted, isDirty, errors },
  } = useFormContext();

  const codice_contatto = watch('codice_contatto');
  const barcodeResult = watch('codice_fiscale');
  const [debouncedCode] = useDebounce(barcodeResult, 800);

  useEffect(() => {
    if (!debouncedCode) {
      return;
    }

    const cf = codiceFiscaleReverse(debouncedCode);

    if (cf) {
      setValue('sesso', cf.gender);
      setValue('luogo_nascita', toTitleCase(cf.birthplace));
      setValue('provincia_nascita', cf.birthplaceProvincia);
      setValue(
        'data_nascita',
        moment(`${cf.year}-${cf.month}-${cf.day}`, 'YYYY-MM-DD')
          .tz('Europe/Rome')
          .set({ hour: 12, minute: 0, second: 0 })
          .toDate(),
      );
    }
  }, [debouncedCode]);

  const hasPos = sessionStorage.getItem('hasPos') === 'true';

  const conditionalSubmit = (data: any) => {
    if (data.data_nascita) {
      data.data_nascita = moment(data.data_nascita).tz('Europe/Rome').set({ hour: 12, minute: 0, second: 0 }).toDate();
    }
    onSubmit(data, { isDirty });
  };

  const conditionalSubmitAndCreateDonazione = (data: any) => {
    if (data.data_nascita) {
      data.data_nascita = moment(data.data_nascita).tz('Europe/Rome').set({ hour: 12, minute: 0, second: 0 }).toDate();
    }
    onSubmit(data, { next: 'createDonazione', isDirty });
  };

  const setAddress = (formattedAddress: FormattedAddress) => {
    setValue('nazione', formattedAddress.nazione, { shouldDirty: true });
    setValue('provincia', formattedAddress.provincia);
    setValue('citta', formattedAddress.citta);
    setValue('cap', formattedAddress.cap);
    setValue('indirizzo', formattedAddress.indirizzo);
    setValue('indirizzo_civico', formattedAddress.indirizzo_civico);
  };

  const requiredFieldsMessage = useMemo(() => {
    const errorsMessages = [];
    if (errors.indirizzo_email) {
      errorsMessages.push('Email');
    }
    if (errors.numero_telefono) {
      errorsMessages.push('Telefono');
    }
    if (errors.indirizzo || errors.citta || errors.cap || errors.provincia) {
      errorsMessages.push('Indirizzo di residenza');
    }

    return errorsMessages.join(' - ');
  }, [errors]);

  return (
    <div className="container-main">
      <h1>{location.pathname === '/update-contact' ? ' Modifica anagrafica' : 'Nuova anagrafica'}</h1>
      <form>
        {hasPos && <CodeChip code={codice_contatto} />}

        <Controller
          name="nome"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              InputLabelProps={{ shrink: true }}
              size="small"
              label="Nome *"
              {...field}
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
        <Controller
          name="cognome"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              InputLabelProps={{ shrink: true }}
              size="small"
              label="Cognome *"
              {...field}
              error={!!error}
              helperText={error?.message}
            />
          )}
        />

        <Controller
          name="sesso"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <FormControl size="small">
              <InputLabel>Sesso</InputLabel>
              <Select label="Sesso" {...field} value={field.value}>
                <MenuItem value="M">Maschio</MenuItem>
                <MenuItem value="F">Femmina</MenuItem>
              </Select>
            </FormControl>
          )}
        />

        <Controller
          name="codice_fiscale"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              InputLabelProps={{ shrink: true }}
              size="small"
              label="Codice fiscale"
              {...field}
              error={!!error}
              helperText={error?.message}
            />
          )}
        />

        <Controller
          name="data_nascita"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <DateField
              InputLabelProps={{ shrink: true }}
              label="Data di nascita"
              size="small"
              {...field}
              format="DD/MM/YYYY"
              value={field.value ? moment(field.value) : null}
              helperText={error?.message}
            />
          )}
        />

        <Controller
          name="luogo_nascita"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              InputLabelProps={{ shrink: true }}
              size="small"
              label="Luogo di nascita"
              {...field}
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
        <Controller
          name="provincia_nascita"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              InputLabelProps={{ shrink: true }}
              size="small"
              label="Provincia di nascita"
              {...field}
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
        <Controller
          name="indirizzo_email"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              InputLabelProps={{ shrink: true }}
              size="small"
              label="Email"
              {...field}
              error={!!error}
              helperText={error?.message}
            />
          )}
        />

        <Controller
          name="numero_telefono"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              InputLabelProps={{ shrink: true }}
              size="small"
              type="number"
              label="Telefono"
              {...field}
              error={!!error}
              helperText={error?.message}
            />
          )}
        />

        <h2>Residenza</h2>

        <SearchAddress
          label="Indirizzo di residenza"
          helperText={"Cerca l'indirizzo per compilare i campi sottostanti relativi alla residenza"}
          onSelect={setAddress}
        />

        <Controller
          name="nazione"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              InputLabelProps={{ shrink: true }}
              size="small"
              label="Nazione di residenza"
              {...field}
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
        <Controller
          name="provincia"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              InputLabelProps={{ shrink: true }}
              size="small"
              label="Provincia di residenza"
              {...field}
              error={!!error}
              helperText={error?.message}
            />
          )}
        />

        <Controller
          name="citta"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              InputLabelProps={{ shrink: true }}
              size="small"
              label="Comune di residenza"
              {...field}
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
        <Controller
          name="cap"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              InputLabelProps={{ shrink: true }}
              size="small"
              label="CAP di residenza"
              {...field}
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
        <Controller
          name="indirizzo"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              InputLabelProps={{ shrink: true }}
              size="small"
              label="Indirizzo di residenza"
              {...field}
              error={!!error}
              helperText={error?.message}
            />
          )}
        />

        <Controller
          name="indirizzo_civico"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              InputLabelProps={{ shrink: true }}
              size="small"
              label="Civico di residenza"
              {...field}
              error={!!error}
              helperText={error?.message}
            />
          )}
        />

        <div>
          <h2>Donazioni</h2>
          <p> Vuoi il riepilogo delle donazioni?</p>

          <FormControl>
            <Controller
              name="riepilogo_donazioni"
              control={control}
              defaultValue={false}
              render={({ field, fieldState: { error } }) => (
                <>
                  <RadioGroup {...field} row>
                    <FormControlLabel value={true} control={<Radio />} label="Sì" />
                    <FormControlLabel value={false} control={<Radio />} label="No" />
                  </RadioGroup>
                  {!!error && <span style={{ color: 'red' }}>{error.message}</span>}
                </>
              )}
            />
          </FormControl>
        </div>

        <Consensi />

        {!isValid && isSubmitted && (
          <>
            <Alert severity="error"> Controlla tutti i campi</Alert>
            {requiredFieldsMessage && <Alert severity="error">Compila questi campi: {requiredFieldsMessage}</Alert>}
          </>
        )}

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, marginTop: 4 }}>
          {hasPos ? (
            <Button
              variant={isDirty ? 'contained' : 'outlined'}
              type="submit"
              disabled={isLoading}
              onClick={handleSubmit(conditionalSubmit)}
              startIcon={isLoading && <CircularProgress size="1rem" color="inherit" />}
            >
              {isDirty ? 'Salva ed Esci ' : 'Torna alla home'}
            </Button>
          ) : (
            <Button
              variant="contained"
              type="submit"
              disabled={isLoading}
              onClick={handleSubmit(conditionalSubmitAndCreateDonazione)}
              startIcon={isLoading && <CircularProgress size="1rem" color="inherit" />}
            >
              {isDirty ? 'Salva e crea donazione ' : 'Crea donazione'}
            </Button>
          )}
        </Box>
      </form>
    </div>
  );
};
