import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Box } from '@mui/material';
import { OCRSearchParams } from '../../interface/ocr';

interface PopoverButtonProps {
  searchParams: OCRSearchParams;
}

export default function PopoverButton({ searchParams }: PopoverButtonProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const renderPopoverText = () => {
    switch (searchParams.type) {
      case 'receipt':
        return <Typography sx={{ p: 2 }}>Inquadra la ricevuta e premi il pulsante per fotografare.</Typography>;
      case 'document':
        if (searchParams.name === 'cie') {
          return (
            <Typography sx={{ p: 2 }}>
              Inquadra il lato POSTERIORE della Carta d'identità e premi il pulsante per fotografare.
            </Typography>
          );
        }
        return (
          <Typography sx={{ p: 2 }}>
            Inquadra il lato POSTERIORE della Tessera Sanitaria e premi il pulsante per fotografare.
          </Typography>
        );
      default:
        return <Typography sx={{ p: 2 }}>inquadra il documento e premi il pulsante per fotografare.</Typography>;
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box
      sx={{
        marginBottom: 1,
      }}
    >
      <Button
        variant="outlined"
        onClick={handleClick}
        sx={{
          height: 30,
          paddingInline: 1,
        }}
      >
        Come si usa
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {renderPopoverText()}
      </Popover>
    </Box>
  );
}
